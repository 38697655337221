import { BaseXHR } from '@utils/axios';
import {
  take,
  fork,
  select,
  call,
  put,
  takeLatest,
  all,
} from 'redux-saga/effects';
import { isServer } from '@utils/helper';
import {
  actions,
  changeProjectAction,
  GET_DETAIL_PROJECT,
  GET_INFO_USER,
} from './slice';
import { selectListProject } from './selectors';

export function* watchAndLog() {
  while (true) {
    try {
      const styleConsole =
        'background: yellow; font-weight: bold; color: #40a9ff8c;';
      const action = yield take('*');
      const state = yield select();
      if (action.type !== '@@router/LOCATION_CHANGE') {
        console.group(action.type);
        console.log('%cBefore State', styleConsole, state);
        console.info('%cDispatching', styleConsole, action);
        console.log('%cNext State', styleConsole, state);
        console.groupEnd();
      }
    } catch {}
  }
}

export function* getDetailProject({ payload }: any) {
  try {
    const listProject = yield select(selectListProject);

    yield put(actions.resetPage());

    if (!listProject.length) {
      const data = yield all(
        [
          process.env.TOUR_ID_0 ||
            process.env.NEXT_PUBLIC_TOUR_ID_0 ||
            '66fca962a09f997e1f289d38',
          // process.env.TOUR_ID_1 ||
          //   process.env.NEXT_PUBLIC_TOUR_ID_1 ||
          //   'mbf30-qua-khu',
          // process.env.TOUR_ID_2 ||
          //   process.env.NEXT_PUBLIC_TOUR_ID_2 ||
          //   'mbf30-hien-tai',
          // process.env.TOUR_ID_3 ||
          //   process.env.NEXT_PUBLIC_TOUR_ID_3 ||
          //   'mbf30-tuong-lai',
        ].map(item => {
          const url = `${process.env.NEXT_PUBLIC_API_URL}project/getPublic?slug=${item}`;
          // const url = `http://localhost:3003${process.env.NEXT_PUBLIC_SUBFOLDER}/${item}.json`;
          return call(() =>
            fetch(url)
              .then((response: any) => {
                return response.json();
              })
              .then(myJson => myJson),
          );
        }),
      );
      if (data.length) {
        yield put(actions.setListProject(data.map(item => item.data || item)));
        if (payload.id) {
          const tour = data.find(
            item => (item.slug || item.data.slug) === payload.id,
          );
          yield put(GET_DETAIL_PROJECT.success(tour.data || tour));
        }
      }
    } else {
      if (payload.id) {
        const tour = yield listProject.find(item => item.slug === payload.id);
        yield put(GET_DETAIL_PROJECT.success(tour));
      }
    }
  } catch (err) {
    console.log(err);
    yield put(GET_DETAIL_PROJECT.failure());
  }
}

export function* getInfoUser({ payload }: any) {
  try {
    const { data } = yield call(BaseXHR.$getWithAuth, '/account/profile', {
      headers: {
        Authorization: `Bearer ${payload.token}`,
      },
    });

    yield put(GET_INFO_USER.success(data.data));
  } catch (err) {
    yield put(GET_INFO_USER.failure());
  }
}

export function* onChangeProject({ payload }: any) {
  try {
    yield put(actions.resetPage());
    const listProject = yield select(selectListProject);
    const tour = yield listProject.find(item => item.slug === payload.slug);
    yield put(GET_DETAIL_PROJECT.success(tour));
  } catch (err) {}
}

const homeSagas: any = [
  takeLatest(GET_INFO_USER.TRIGGER, getInfoUser),
  takeLatest(GET_DETAIL_PROJECT.TRIGGER, getDetailProject),
  takeLatest(changeProjectAction, onChangeProject),
];

if (!isServer && process.env.NODE_ENV !== 'production')
  homeSagas.push(fork(watchAndLog));

export default homeSagas;
